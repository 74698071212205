import React, { useState, useEffect, useRef } from "react";
import Title from "../Title/Title";
import Desc from "../Desc/Desc";
import Button from "../Button/Button";
import Paragraph from "../Paragraph/Paragraph";
import styles from "./Result.module.scss";
import classNames from "classnames";
import { SITE_URL } from "../../state/consts";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { FacebookShareButton, WhatsappShareButton } from "react-share";

import { FacebookIcon, WhatsappIcon } from "react-share";

function Result({
  score,
  title,
  text,
  imageSrc,
  posterContainer,
  optionsContainer,
  handlerBack,
}) {
  const [offset, setOffset] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [showConditions, setShowConditions] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setOffset(true);
      } else {
        setOffset(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ImageURL = SITE_URL + imageSrc;

  // кнопка скопировать
  const [open, setOpen] = useState(false);

  const testClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(window.location.toString());
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    console.log(textAreaRef.current.value);
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess(
      <div className={styles.copySuccess}>Промокод скопирован.</div>
    );

    setTimeout(() => {
      setCopySuccess(
        <div className={classNames(styles.copySuccess, styles.displayNone)}>
          Промокод скопирован.
        </div>
      );
    }, "1500");
  }

  return (
    <>
      {!showConditions ? (
        <>
          <div className={posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={classNames(optionsContainer, styles.before)}>
            <div
              className={classNames({
                [styles.resultPosterContainer]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div className={styles.contentWrapper}>
                <div className={styles.score}>{score}</div>
                <Title value={title} />
                {text}
                <Desc
                  value={
                    "Use the Maxim app when you need to transport furniture, building materials or household appliances. Select the rate you need in the Trucks tab."
                  }
                />
              </div>

              <div className={styles.promoBox}>
                <div className={styles.promoButtonsWrapper}>
                  <div className={styles.promoWrapper}>
                    <div className={styles.inputPromoWrapper}>
                      <form className={styles.form}>
                        <input
                          ref={textAreaRef}
                          value="TRUCK"
                          className={styles.inputPromo}
                          readOnly
                        />
                      </form>

                      <button onClick={copyToClipboard} className={styles.copy}>
                        <ContentCopyIcon />
                      </button>
                    </div>
                  </div>

                  <div className={styles.buttonBox}>
                    <a
                      href="https://taximaxim.onelink.me/KKXl?af_xp=custom&pid=grdeep_push&c=grdeep_promo_ph_group_A&is_retargeting=true&af_reengagement_window=30d&af_dp=maximzakaz%3A%2F%2Fapp%3Faction%3Dpromo%26code%3DTRUCK"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        value="Activate promo code"
                        className={styles.buttonPresent}
                      />
                    </a>

                    <div
                      className={styles.buttonBoxLink}
                      onClick={() => {
                        setShowConditions(true);
                      }}
                    >
                      Promotion terms and conditions
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.contentWrapper,
                  styles.socialWrapper
                )}
              >
                <Paragraph value="Share" />
                <div className={styles.social}>
                  <FacebookShareButton
                    url={SITE_URL}
                    title="I helped Maximka move into a new apartment! Play a game by Maxim and learn how to make your moving as simple as possible."
                    image={ImageURL}
                    hashtag="#Maxim"
                    id="facebook"
                  >
                    <FacebookIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.facebook)}
                    />
                  </FacebookShareButton>

                  <WhatsappShareButton
                    url={SITE_URL}
                    title="I helped Maximka move into a new apartment! Play a game by Maxim and learn how to make your moving as simple as possible."
                    image={ImageURL}
                    id="whatsapp"
                  >
                    <WhatsappIcon
                      size={48}
                      round={true}
                      className={classNames(styles.icon, styles.whatsapp)}
                    />
                  </WhatsappShareButton>

                  <div id="sharelink">
                    <IconButton
                      onClick={testClick}
                      color="primary"
                      className={styles.share}
                    >
                      <ShareIcon />
                    </IconButton>
                    <Snackbar
                      message="Copied to clipboard"
                      anchorOrigin={{ vertical: "top", horizontal: "center" }}
                      autoHideDuration={1000}
                      onClose={() => setOpen(false)}
                      open={open}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {copySuccess}
        </>
      ) : (
        <>
          <div className={styles.posterContainer}>
            <img src={imageSrc} className={styles.image} alt="" />
          </div>
          <div className={styles.resultContainer} m2>
            <div
              className={classNames({
                [styles.resultContainerBox]: true,
                [styles.onScrollOption]: offset,
              })}
            >
              <div>
                <Paragraph value="The move went smoothly: Maximka moved all of his things in one go, and the same evening he was already organizing his new apartment. Check out for yourself how convenient it is to transport freight with Maxim. Activate the promo code TRUCK in the app and get 300 bonus pesos to spend on orders." />

                <Button
                  className={styles.buttonConditions}
                  value="Back"
                  onClick={() => {
                    setShowConditions(false);
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Result;
